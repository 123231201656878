<template>
  <v-card>
    <v-card-text>
      <v-row class="pa-3" align="center" justify="space-between" v-if="podeCriar">
        <v-col cols="2">
          <h2>Salas</h2>
        </v-col>
        <v-col class="justify-end d-flex">
          <v-btn
              class="ml-2"
              small
              :disabled="loading"
              color="primary"
              @click="criarSalaLocalProva"
          >
            <v-icon left>mdi-plus-thick</v-icon>
            Adicionar
          </v-btn>
          <v-dialog max-width="800">
            <template v-slot:activator="{on, attrs}">
              <v-btn
                  class="ml-2"
                  small
                  v-on="on"
                  v-bind="attrs"
                  :disabled="loading"
                  color="error"
              >
                <v-icon left>mdi-delete</v-icon>
                Remover Todas
              </v-btn>
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <v-card-title class="primary justify-start white--text"
                >Confirmar Exclusão
                </v-card-title>
                <v-card-text
                    style="font-size: 15pt; text-align: center"
                    class="mt-5 font-weight-bold"
                >
                  Deseja Realmente Excluir todas as Salas?
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn
                      color="primary"
                      @click="
                        excluirSalas();
                        dialog.value = false;
                      "
                  >Confirmar
                  </v-btn>
                  <v-btn color="error" @click="dialog.value = false"
                  >Cancelar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-col>
      </v-row>
      <v-data-table
          :headers="headersSalas"
          :items="salasLocalProva"
          :items-per-page="5"
          class="elevation-1"
      >
        <template
            v-for="header in headersSalas.filter(h => !h.transient)" v-slot:[`item.${header.value}`]="{ value }"
        >
          {{ defaultValue(header, value) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-row justify="center" align="center">
            <v-col cols="6">
              <v-dialog width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      small
                      :disabled="loading"
                      v-bind="attrs"
                      v-on="on"
                      class="mx-2"
                      color="error"
                      icon
                      x-small
                  >
                    <v-icon>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-card-title class="primary justify-start white--text"
                    > {{ podeCriar ? "Confirmar Exclusão"
                                   : "Confirmar Desvinculação" }}
                    </v-card-title>
                    <v-card-text
                        style="font-size: 15pt; text-align: center"
                        class="mt-5 font-weight-bold"
                    > {{ podeCriar ? "Deseja realmente excluir esta Sala? Esta ação não poderá ser desfeita!"
                                   : "Deseja realmente desvincular esta Sala? Esta ação não apaga a sala, apenas a desvincula desta opção" }}
                    </v-card-text>
                    <v-card-actions class="justify-center">
                      <v-btn
                          color="primary"
                          @click="
                        excluirSalaLocalProva(item);
                        dialog.value = false;
                      "
                      >Confirmar
                      </v-btn>
                      <v-btn color="error" @click="dialog.value = false"
                      >Cancelar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </v-col>
            <v-col cols="6">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-on="on"
                      v-bind="attrs"
                      color="accent"
                      class="mr-3"
                      icon
                      x-small
                      @click="editarSalaLocalProva(item)"
                  >
                    <v-icon>
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </template>
                <span>Editar Sala</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog v-if="dialog" v-model="dialog" width="50%"
              persistent
              scrollable>
      <v-card>
        <v-card-title class="primary justify-start white--text">
          {{ isCriandoSalaLocalProva ? "Nova Sala" : "Atualizar Sala" }}
          <v-spacer/>
          <v-btn icon color="white" @click="cancelarEdicaoSalaLocalProva">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="edicaoSalaLocalProvaForm"
                  @submit.prevent="salvarSalaLocalProva" :disabled="salaLocalProvaEditado.quantidadeAlocada > 0">
            <v-card flat>
              <v-card-text>
                <v-row dense>
                  <v-col cols="12">
                    <v-text-field
                        v-model="salaLocalProvaEditado.sala"
                        :rules="[campoObrigatorioRule]"
                        dense
                        label="Sala"
                        name="salaLocalProvaSala"
                        outlined
                        validate-on-blur
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                        v-model="salaLocalProvaEditado.bloco"
                        dense
                        label="Bloco"
                        name="salaLocalProvaBloco"
                        outlined
                        validate-on-blur
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                        v-model="salaLocalProvaEditado.andar"
                        dense
                        label="Andar"
                        name="salaLocalProvaAndar"
                        outlined
                        validate-on-blur
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                        v-model="salaLocalProvaEditado.capacidadeTotal"
                        :rules="[campoObrigatorioRule]"
                        dense
                        label="Capacidade Total *"
                        name="localProvaCapacidadeTotal"
                        outlined
                        validate-on-blur
                    />
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                        v-model="salaLocalProvaEditado.capacidadeDisponivelUtilizacao"
                        :rules="[campoObrigatorioRule]"
                        dense
                        label="Capacidade Disponível para Utilização *"
                        name="localProvaCapacidadeTotalDisponivel"
                        outlined
                        validate-on-blur
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                        v-model="salaLocalProvaEditado.habilitada"
                        :label="`Habilitado`"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                        v-model="salaLocalProvaEditado.salaNecessidadeEspecial"
                        :label="`Sala com Acessibilidade`"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-select
                        v-model="salaLocalProvaEditado.prioridadeAlocacao"
                        dense
                        :items="prioridades"
                        item-text="descricao"
                        item-value="valor"
                        label="Prioridade para Alocação"
                        :rules="[campoObrigatorioRule]"
                        outlined
                        validate-on-blur
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-select
                        v-model="salaLocalProvaEditado.turno"
                        dense
                        :items="turnos"
                        label="Turno *"
                        :rules="[campoObrigatorioRule]"
                        outlined
                        validate-on-blur
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" @click.prevent="cancelarEdicaoSalaLocalProva">
                Cancelar
              </v-btn>
              <v-btn color="primary" type="submit">
                Salvar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>

import salaLocalProvaService from "@/services/salaLocalProva.service";
import {mapActions} from "vuex";
import rules from "@/commons/rules";
import _ from "lodash";

export default {
  name: "SalaLocalProva",

  props: {
    salasLocalProva: {type: Array, required: true},
    entidadeOid: {type: String, required: false},
    podeCriar: {type: Boolean, required: false, default: true}
  },

  data() {
    return {
      dialog: false,
      dialogExclusao: false,
      headersSalas: [
        {text: 'Local', value: 'localProva.nome'},
        {text: 'Sala', value: 'sala'},
        {text: 'Bloco', value: 'bloco'},
        {text: 'Andar', value: 'andar'},
        {text: 'Turno', value: 'turno'},
        {text: 'Dia Prova', value: 'localProva.diaProva', align: 'center'},
        {text: 'Capacidade Total', value: 'capacidadeTotal', align: 'center'},
        {text: 'Capacidade Disponível para Utilização', value: 'capacidadeDisponivelUtilizacao', align: 'center'},
        {text: 'Quantidade Alocada', value: 'quantidadeAlocada', align: 'center'},
        {text: 'Necessidade Especial', value: 'salaNecessidadeEspecial', align: 'center'},
        {text: 'Ações', value: "actions", sortable: false, align: 'center', transient: true}
      ],
      turnos: ["Manhã", "Tarde", "Noite", "Integral"],
      prioridades: [
        {descricao: "Baixa", valor: 3},
        {descricao: "Média", valor: 2},
        {descricao: "Alta", valor: 1},
      ],
      salaLocalProvaEditado: {},
      loading: false
    }
  },

  computed: {
    isCriandoSalaLocalProva() {
      return !this.salaLocalProvaEditado.oid
    }
  },

  methods: {
    ...rules,
    ...mapActions("avisos", ["exibirAviso"]),

    async excluirSalaLocalProva(salaLocalProva) {
      this.$emit("excluirSalaLocalProva", salaLocalProva)
    },

    async excluirSalas() {
      let loader = this.$loading.show();
      await salaLocalProvaService.excluirSalasLocalProva(this.entidadeOid).then(() => {
        this.$emit('atualizarSalas', this.entidadeOid);
        this.exibirAviso({
          mensagem: "Salas excluídas com sucesso",
          tipo: "success"
        });
      }).catch((error) => {
        let mensagem = "Erro ao excluir Salas";
        if (error.response.status === 409) {
          mensagem += ": " + error.response.data.msg;
        }
        this.exibirAviso({
          mensagem: mensagem,
          tipo: "error"
        });
      });

      loader.hide();
    },

    defaultValue(header, value) {
      if (typeof value === "boolean") {
        return value ? "Sim" : "Não";
      }

      if (typeof value !== "number" && !value) {
        header.align = "center";
        return "-";
      }

      return value;
    },

    criarSalaLocalProva() {
      this.salaLocalProvaEditado = {
        habilitada: false,
        salaNecessidadeEspecial: false
      };
      this.dialog = true;
    },

    editarSalaLocalProva(item) {
      this.salaLocalProvaEditado = _.cloneDeep(item);
      this.dialog = true;
    },

    cancelarEdicaoSalaLocalProva() {
      this.salaLocalProvaEditado = {};
      this.$refs.edicaoSalaLocalProvaForm.reset();
      this.dialog = false;
    },

    async salvarSalaLocalProva() {
      if (this.$refs.edicaoSalaLocalProvaForm.validate()) {
        let loader = this.$loading.show();

        if (this.isCriandoSalaLocalProva) {
          await salaLocalProvaService.criarSalaLocalProva(this.salaLocalProvaEditado, this.entidadeOid).then(() => {
            this.$emit('atualizarSalas', this.entidadeOid);
            this.exibirAviso({
              mensagem: "Sala cadastrada com sucesso",
              tipo: "success"
            });
          }).catch((error) => {
            let mensagem = "Erro ao cadastrar Sala";
            if (error.response.status === 409) {
              mensagem += ": " + error.response.data.msg;
            }
            this.exibirAviso({
              mensagem: mensagem,
              tipo: "error"
            });
          });
        } else {
          await salaLocalProvaService.atualizarSalaLocalProva(this.salaLocalProvaEditado).then(() => {
            this.$emit('atualizarSalas', this.entidadeOid);
            this.exibirAviso({
              mensagem: "Sala atualizada com sucesso",
              tipo: "success"
            });
          }).catch((error) => {
            let mensagem = "Erro ao atualizar os dados da Sala";
            if (error.response.status === 409) {
              mensagem += ": " + error.response.data.msg;
            }
            this.exibirAviso({
              mensagem: mensagem,
              tipo: "error"
            });

          });
        }

        this.cancelarEdicaoSalaLocalProva();

        loader.hide();
      }
    }
  }
}

</script>

<style scoped>

</style>