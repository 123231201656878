import Vue from "vue";

export default {

    recuperarSalasLocalProvaPorLocalProva(localProvaOid){
        return Vue.axios.get('salaLocalProva/localProva/' + localProvaOid);
    },

    criarSalaLocalProva(salaLocalProva, localProvaOid){

        salaLocalProva = {
            localProva: {oid: localProvaOid},
            ...salaLocalProva
        }

        return Vue.axios.post('salaLocalProva', salaLocalProva);

    },

    atualizarSalaLocalProva(salaLocalProva) {
        return Vue.axios.put("salaLocalProva", salaLocalProva);
    },

    excluirSalaLocalProva(salaLocalProvaOid){
        return Vue.axios.delete('salaLocalProva/excluir/' + salaLocalProvaOid);
    },

    excluirSalasLocalProva(localProvaOid){
        return Vue.axios.delete("salaLocalProva/excluirSalas/" + localProvaOid);
    },

    recuperarSalasDisponiveisParaVincularPorLocalProva(localProvaOid) {
        return Vue.axios.get("salaLocalProva/vincular/localProva/" + localProvaOid);
    }
}
